import { Fragment } from 'react'
import infoSys from 'assets/img/train/summer-institute.png'
import TCSLogo from 'assets/img/common/TCS-logo.png'

export const banner = {
  bannerTitle: 'AI will change',
  rotatingWords: [
    'Healthcare',
    'Finance',
    'Policymaking',
    'Conservation',
    'Defense',
    'Education',
    'Aerospace',
    'Entertainment',
  ],
  bannerSubTitle: 'Prepare your students to be responsible ai leaders',
  secondSubTitle: [
    'Artificial Intelligence Professional Development for High School Educators',
    'Virtual',
    <Fragment>
      <span className="font-seg-ui-reg">July 21 - 25, 2025</span>
    </Fragment>,
  ],
  thirdSubTitle: (
    <Fragment>
      <span className="text-bold">FREE - sponsored by </span>{' '}
      <img
        className="ml-20 bg-white p-10"
        style={{ maxWidth: 150 }}
        src={infoSys}
        alt="Info System"
      />
    </Fragment>
  ),
  buttons: [
    {
      isExternal: true,
      title: 'Create Account',
      link: 'https://pathfinders.onwingspan.com/web/en/login?ref=%2Fapp%2Fevent-details%2Fthe-coding-school',
    },
    {
      isExternal: true,
      title: 'Register',
      link: 'https://pathfinders.onwingspan.com/web/en/app/event-details/the-coding-school',
    },
  ],
  afterButtonText: (
    <Fragment>
      *To register: create an account on the Pathfinders site. Next, click on
      the ‘register’ button & enroll in the course.
    </Fragment>
  ),
}
