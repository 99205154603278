import { Link } from 'react-router-dom'
import classNames from 'classnames'

import styles from './styles.module.scss'

export const HowToApply = props => {
  const { data } = props
  return (
    <div className={styles.howToApply}>
      {data?.title && <h4 className={styles.title}>{data?.title}</h4>}
      {data?.descriptions?.map((item, idx) => {
        return (
          <p className={styles.keyDetailsDesc} key={idx}>
            {item}
          </p>
        )
      })}
      {data?.highlightedPoint?.map((item, idx) => {
        return (
          <p className={classNames(styles.featuresDesc)} key={idx}>
            <span className={styles.icon}>
              <i className={classNames(styles.icon, 'fa-solid fa-brain')}></i>
            </span>
            <span className="text-bold">{item}</span>
          </p>
        )
      })}

      {data?.button && (
        <div className={styles.buttonsWrap}>
          {data.button.map((button, idx) =>
            button?.isExternal ? (
              <a
                className="btn btn-primary-gradient text-bold text-white text-upper rounded-0 px-30 py-10 mt-20"
                href={button.link}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                {button?.buttonText ? button.buttonText : 'Interest Form'}
              </a>
            ) : (
              <Link
                className="btn btn-primary-gradient text-bold text-white text-upper rounded-0 px-30 py-10 mt-20"
                to={button?.link}
                key={idx}
              >
                {button?.buttonText ? button.buttonText : 'Interest Form'}
              </Link>
            )
          )}
        </div>
      )}
    </div>
  )
}
