import { Fragment } from 'react'

import bg from 'assets/img/involved/volunteer.png'

import quantumComputing from 'assets/img/home/upcoming-programs-5.jpg'
import techCampMiddleSchool from 'assets/img/summer-programs/emerging-tech-new.jpg'
import techCamp from 'assets/img/summer-programs/tech-camp.jpg'

import quantumComputingLab from 'assets/img/home/quantum-computing-lab.png'
import dataScienceResearch from 'assets/img/home/k-12-research-opportunity.jpg'
import techCampHighSchool from 'assets/img/summer-programs/emerging-tech-3.webp'
import hspAiBigData from 'assets/img/summer-programs/ai-big-data.jpg'
import ppeArtificialIntelligence from 'assets/img/summer-programs/quantum-research.jpg'
import hspAiResearch from 'assets/img/summer-programs/ai-research.jpg'

import quantumImmersion from 'assets/img/summer-programs/uni-quantum-career-2.jpg'

import aiCloud from 'assets/img/summer-programs/ai-cloud.jpg'
import quantumComputingPd from 'assets/img/summer-programs/quantum-computing-pd.jpg'

import emergingTechImg from 'assets/img/summer-programs/emerging-tech-new-2.jpg'
import aiBigData from 'assets/img/summer-programs/ai-big-data-2.jpg'
import quantumComputingProgram from 'assets/img/summer-programs/quantum-computing.jpg'

import iconAI from 'assets/img/summer-programs/icon-ai.png'
import iconCybersecurity from 'assets/img/summer-programs/icon-cybersecurity.png'
import iconHealthcare from 'assets/img/summer-programs/icon-healthcare.png'
import iconWebDevelopment from 'assets/img/summer-programs/icon-web-development.png'
import iconArtAndTec from 'assets/img/summer-programs/icon-art-tech.png'

import etSlideOne from 'assets/img/summer-programs/et-slide-1.png'
import etSlideTwo from 'assets/img/summer-programs/et-slide-2.png'
import etSlideThree from 'assets/img/summer-programs/et-slide-3.png'
import etSlideFour from 'assets/img/summer-programs/et-slide-4.png'

import aiSlideOne from 'assets/img/summer-programs/ai-slide-1.jpg'

export const emergingTech = {
  header: {
    headerTitle: 'Our Emerging Tech Programs',
    headerDesc: (
      <Fragment>
        Accredited by WASC & partnered with the White House, DoD, Microsoft,
        Google Quantum AI, IBM Quantum, Stanford & more, we offer the{' '}
        <span className="text-bold text-brand">
          most supportive, in-depth & engaging
        </span>{' '}
        tech education for K-12 & beyond
      </Fragment>
    ),
  },
  sectionsButtons: [
    { sectionId: 'highSchool', sectionName: 'High school' },
    { sectionId: 'university', sectionName: 'University' },
    { sectionId: 'educators', sectionName: 'Educators' },
  ],
  sectionsDetail: [
    {
      sectionData: {
        sectionId: 'highSchool',
        sectionHeading: 'High School Programs',
        cards: [
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: emergingTechImg,
            cardTitle: 'Emerging Tech Webinar Series',
            cardSubtitle: (
              <Fragment>
                FREE
                <br />
                High School Students
              </Fragment>
            ),
            keyPoints: [
              '1-hour live-instruction',
              'Webinars in Emerging Tech, AI, & Data Science',
              'Jan - June 2025',
              'Learn foundational concepts',
              'Get inspired for career paths',
            ],
            button: [
              {
                external: true,
                text: 'Register',
                link: 'https://linktr.ee/the_coding_school',
              },
            ],
          },
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: dataScienceResearch,
            cardTitle: <Fragment>National High School Research Program <small>with</small> Columbia University</Fragment>,
            cardSubtitle: (
              <Fragment>
                Grades: 9 - 13
              </Fragment>
            ),
            keyPoints: [
              'Increase college competitiveness',
              'Data Science, AI & Quantum Computing opportunities',
              '5-week summer intensive',
              'Collaborate with academic & industry professionals',
              'Create research project to showcase',
            ],
            button: [
              {
                external: false,
                text: 'Learn more',
                link: '/nhsrp',
              },
              {
                external: false,
                text: 'Apply Now',
                link: '/nhsrp-application',
              },
            ],
          }
        ],
      },
      testimonials: {
        background: bg,
        testimony:
          'I gained significant research experience, and I learned an entire coding language. This program boosts my college app, and only spiked my interest in statistics and data science more with real life applications to the concepts I was already fascinated about.',
        testifier: '11th grade student, Data Science Research Program',
      },
    },
    {
      sectionData: {
        sectionId: 'university',
        sectionHeading: 'University Students Programs',
        cards: [
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: quantumComputing,
            cardTitle: 'Quantum Summer Institute',
            cardSubtitle: (
              <Fragment>
                University students
                <span className="d-block">May 27 - June 20, 2025</span>
              </Fragment>
            ),
            keyPoints: [
              'Develop real quantum skills',
              '4-week intensive quantum training',
              'Construct circuits and quantum gates',
              'Learn quantum algorithms',
              'Run code on a quantum computer',
            ],
            button: [
              {
                external: true,
                text: 'Learn more',
                link: 'https://www.qubitbyqubit.org/qsi',
              },
              {
                external: true,
                text: 'Apply Now',
                link: 'https://www.jotform.com/form/250306119741147',
              },
            ],
          },
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: quantumImmersion,
            cardTitle: 'Early Quantum Career Immersion',
            cardSubtitle: <Fragment>University students <br /> May 27 - August 8, 2025</Fragment>,
            keyPoints: [
              'Complete a research project or be placed in an industry internship after completing Quantum Summer Institute',
              'Develop critical skills for a future career in quantum',
              'Learn about academic and career pathways from quantum mentors',
            ],
            button: [
              {
                external: true,
                text: 'Learn more',
                link: 'https://www.qubitbyqubit.org/eqci',
              },
              {
                external: true,
                text: 'Apply Now',
                link: 'https://www.jotform.com/build/243375975761167',
              },
            ],
          },
        ],
      },
      testimonials: {
        background: bg,
        testimony:
          'Qubit by Qubit has given me the opportunity to be introduced to quantum computing at a pace that is digestible and has provided insight on where I see myself in the field of quantum computing…Being surrounded by students that share your similar background is very empowering and has definitely supported my learning process.',
        testifier: 'University student, Quantum Computing Internship Program',
      },
    },
    {
      sectionData: {
        sectionId: 'educators',
        sectionHeading: 'Educators',
        cards: [
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: techCamp,
            cardTitle: 'Artificial Intelligence PD',
            cardSubtitle: (
              <Fragment>
                US Public School Educators
                <span className="d-block">FREE</span>
                <span className="d-block">July 21 - 25, 2025</span>
              </Fragment>
            ),
            keyPoints: [
              'Learn about the cutting edge field of artificial intelligence',
              'Explore how to integrate AI/ML into your existing classes',
              'Develop a network of STEM Educators',
              'CEU Opportunity',
            ],
            button: [
              {
                external: false,
                text: 'Learn More & Register',
                link: '/train-2-weeks-pd',
              },
            ],
          },
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: quantumComputingPd,
            cardTitle: 'Emerging Tech Workshops',
            cardSubtitle: (
              <Fragment>
                Middle & High School classrooms
                <span className="d-block">Jan - June 2025</span>
              </Fragment>
            ),
            keyPoints: [
              'Bring AI, Data Science & Quantum into your classrooms',
              'Inspire tech & non-tech students with cutting-edge skills',
              'Hands-on activities',
              'FREE instructor-led workshops sponsored by Keysight Technologies',
            ],
            button: [
              {
                external: true,
                text: 'Request a workshop',
                link: 'https://airtable.com/appAgeNP0hq6rXtT0/pagifuoMwrfUdxzoB/form',
              },
            ],
          },
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: aiCloud,
            cardTitle: (
              <Fragment>
                Intro to Machine Learning Bootcamp
                <small className="text-thin"> with</small> AWS
              </Fragment>
            ),
            cardSubtitle: (
              <Fragment>
                Community College, HBCU & MSI Faculty
                <span className="d-block">2025 bootcamps announced soon</span>
              </Fragment>
            ),
            keyPoints: [
              'Learn tangible artificial intelligence and machine learning skills',
              'Earn CEU credits from Johns Hopkins University',
              'Collaborate with a community of educators nationwide',
            ],
            button: [
              {
                external: false,
                text: 'Learn more',
                link: '/train-aws-pd',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
        ],
      },
      testimonials: {
        background: bg,
        testimony:
          'This program was a true accelerator in my understanding, knowledge and ability to integrate ML and AI concepts into my teaching and learning. It is an intensive, and through this immersion in ML concepts and coding, you begin to think like a coder! I enjoyed this intensive PD for expanding my thinking about ML concepts broadly, and also how to apply them to my classroom and with my students. My colleagues, the experts, the program manager, all made this very accessible.',
        testifier: (
          <Fragment>
            High School Educator, TR
            <span className="text-bold text-gold">AI</span>N PD
          </Fragment>
        ),
      },
    },
  ],
}
