export const banner = {
  bannerTitle: 'AI will change',
  rotatingWords: [
    'Healthcare',
    'Finance',
    'Policymaking',
    'Conservation',
    'Defense',
    'Education',
    'Aerospace',
    'Entertainment',
  ],
  bannerSubTitle: 'BE A PART OF THE AI REVOLUTION.',
  buttons: [
    {
      isExternal: true,
      title: '2024-25 Interest Form',
      link: 'https://airtable.com/appdS9n7K25uof6m4/shrhHmSkMlGPdjUHM',
    },
  ],
}
