import { Fragment } from 'react'

export const banner = {
  bannerTitle: 'AI will change',
  rotatingWords: [
    'Healthcare',
    'Finance',
    'Policymaking',
    'Conservation',
    'Defense',
    'Education',
    'Aerospace',
    'Entertainment',
  ],
  bannerSubTitle: 'BE A PART OF THE AI REVOLUTION.',
  additionalData: [
    <Fragment>
      <h3 style={{ fontSize: '44px' }} className="font-seg-ui-bold my-30">
        Foundations of Data Science & Machine Learning <br /> for High School
        Students
      </h3>
    </Fragment>,
    <Fragment>
      <h3 className="font-seg-ui-reg">September - December 2025</h3>
    </Fragment>,
  ],
  buttons: [
    {
      isExternal: true,
      title: 'Interest Form',
      link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
    },
  ],
}
