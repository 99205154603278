import { disclaimer } from 'data/trainCourse'

import {
  banner,
  coursesPartner,
  aiIntro,
  courseOverview,
  forSchools,
  faqs,
} from 'data/trainPd'

import { TrainCourseBanner } from 'component/TrainCourse/TrainCourseBanner'
import { AiIntro } from 'component/TrainCourse/AiIntro'
import { CoursesBy } from 'component/TrainCourse/CoursesBy'
import { CourseOverview } from 'component/TrainCourse/CourseOverview'
import { ForSchools } from 'component/TrainCourse/ForSchools'
import { CourseSupport } from 'component/TrainCourse/CourseSupport'
import { Faq } from 'component/TrainCourse/Faq'
import { Disclaimer } from 'component/TrainCourse/Disclaimer'

export const TrainPD = () => {
  return (
    <div className="trainPd">
      <TrainCourseBanner
        bannerTitle={banner.bannerTitle}
        rotatingWords={banner.rotatingWords}
        bannerSubTitle={banner.bannerSubTitle}
        secondSubTitle={banner.secondSubTitle}
        buttons={banner.buttons}
        isSmallSubtitle={true}
      />
      <AiIntro
        paragraph={aiIntro.paragraph}
        aiList={aiIntro.aiList}
        tagLine={aiIntro.tagLine}
      />
      <CoursesBy title={coursesPartner.title} logos={coursesPartner.logos} />
      <CourseOverview data={courseOverview} />
      <ForSchools
        subTitle={forSchools.subTitle}
        schoolText={forSchools.schoolText}
        buttonLink={forSchools.buttonLink}
        logos={forSchools.logos}
      />
      <CourseSupport />
      <Faq faqs={faqs} />
      <Disclaimer disclaimer={disclaimer.text} />
    </div>
  )
}
