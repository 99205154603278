import { Fragment } from 'react'

import quantumComputing from 'assets/img/home/upcoming-programs-5.jpg'
import dataScienceResearch from 'assets/img/home/k-12-research-opportunity.jpg'
import summerCamps from 'assets/img/home/upcoming-program-summer-camps.jpg'

import emergingTechImg from 'assets/img/summer-programs/emerging-tech-new-2.jpg'

import artificialIntelligence from 'assets/img/home/upcoming-programs-1-new.jpg'

import quantumComputingLab from 'assets/img/home/quantum-computing-lab.png'
import quantumImmersion from 'assets/img/summer-programs/uni-quantum-career.jpg'

import ppeArtificialIntelligence from 'assets/img/home/upcoming-programs-3-new.jpg'
import ppeQuantumComputing from 'assets/img/home/educator-quantum-computing.jpg'
import aiCloud from 'assets/img/summer-programs/ai-cloud.jpg'

import techCamp from 'assets/img/summer-programs/tech-camp.jpg'
import quantumComputingPd from 'assets/img/summer-programs/quantum-computing-pd.jpg'

export const programPost = [
  // {
  //   id: '1',
  //   title: 'Virtual Summer Camps',
  //   subTitle: 'Grades: 6 - 12',
  //   list: [
  //     { id: '1', listItem: 'Skills in emerging technologies' },
  //     {
  //       id: '2',
  //       listItem: 'AI, Cybersecurity, Arts + Tech & more',
  //     },
  //     { id: '3', listItem: 'Create specialized tech projects' },
  //     { id: '4', listItem: 'Taught by tech professionals' },
  //   ],
  //   img: summerCamps,
  //   links: [
  //     {
  //       text: 'Learn More',
  //       link: '/summer-programs#virtualSummerCamps',
  //       isExternal: false,
  //       isHashLink: true,
  //     },
  //     {
  //       text: 'Register',
  //       link: '/summer-camp-registration',
  //       isExternal: false,
  //     },
  //   ],
  // },
  // {
  //   id: '2',
  //   title: 'Data Science Research',
  //   subTitle: 'Grades: 10 - 13',
  //   list: [
  //     { id: '1', listItem: 'Columbia University professors' },
  //     { id: '2', listItem: 'Develop in-depth data science skills' },
  //     { id: '3', listItem: 'Conduct real-world research' },
  //     { id: '4', listItem: 'Increase college competitiveness' },
  //   ],
  //   img: dataScienceResearch,
  //   links: [
  //     { text: 'Learn More', link: '/research', isExternal: false },
  //     { text: 'Apply', link: '/dsrp-application', isExternal: false },
  //   ],
  // },

  // {
  //   id: '3',
  //   title: 'Quantum Computing',
  //   subTitle: (
  //     <Fragment>
  //       Grades: 9 - 12 & above
  //       <br />
  //       Sept 2025 - April 2026
  //     </Fragment>
  //   ),
  //   list: [
  //     { id: '1', listItem: 'Semester & year-long course options' },
  //     { id: '2', listItem: 'Learn real quantum skills' },
  //     {
  //       id: '3',
  //       listItem: 'Led by Stanford PhDs',
  //     },
  //     { id: '4', listItem: 'Earn transcript credit' },
  //     { id: '5', listItem: 'Code on a quantum computer!' },
  //   ],
  //   img: quantumComputing,
  //   links: [
  //     {
  //       text: 'Learn More',
  //       link: 'https://www.qubitbyqubit.org/programs',
  //       isExternal: true,
  //     },
  //     {
  //       text: 'Interest Form',
  //       isExternal: true,
  //       link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  //     },
  //   ],
  // },
  // {
  //   id: '4',
  //   title: 'Artificial Intelligence',
  //   subTitle: (
  //     <Fragment>
  //       Grades: 9 - 12 <br /> Sept 2025 - April 2026
  //     </Fragment>
  //   ),
  //   list: [
  //     { id: '1', listItem: 'Semester & year-long course options' },
  //     { id: '2', listItem: 'For-credit course' },
  //     { id: '3', listItem: 'In-depth skills in AI/ML' },
  //     { id: '4', listItem: 'AI capstone project' },
  //     { id: '5', listItem: 'Led by Columbia University researchers' },
  //   ],
  //   img: artificialIntelligence,
  //   links: [
  //     { text: 'Learn More', link: '/train', isExternal: false },
  //     {
  //       text: 'Interest Form',
  //       isExternal: true,
  //       link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  //     },
  //   ],
  // },

  {
    id: '1',
    title: 'Emerging Tech Webinar Series',
    subTitle: (
      <Fragment>
        FREE
        <br />
        High School Students
      </Fragment>
    ),
    list: [
      { id: 1, listItem: '1-hour live-instruction' },
      { id: 2, listItem: 'Webinars in Emerging Tech, AI, & Data Science' },
      { id: 3, listItem: 'Jan - June 2025' },
      { id: 4, listItem: 'Learn foundational concepts' },
      { id: 5, listItem: 'Get inspired for career paths' },
    ],
    img: emergingTechImg,
    links: [
      {
        text: 'Register',
        isExternal: true,
        link: 'https://linktr.ee/the_coding_school',
      },
    ],
  },

  {
    id: '2',
    title: <Fragment>National High School Research Program <small>with</small> Columbia University</Fragment>,
    subTitle: (
      <Fragment>
        Grades: 9 - 13
      </Fragment>
    ),
    list: [
      { id: '1', listItem: 'Increase college competitiveness' },
      { id: '2', listItem: 'Data Science, AI & Quantum Computing opportunities' },
      { id: '3', listItem: '5-week summer intensive' },
      { id: '4', listItem: 'Collaborate with academic & industry professionals' },
      { id: '5', listItem: 'Create research project to showcase' },
    ],
    img: dataScienceResearch,
    links: [
      {
        isExternal: false,
        text: 'Learn more',
        link: '/nhsrp',
      },
      {
        isExternal: false,
        text: 'Apply Now',
        link: '/nhsrp-application',
      },
    ],
  },
]

export const programPostUniversity = [
  // {
  //   id: '1',
  //   title: 'Quantum Computing',
  //   subTitle: 'Sept 2025 - April 2026',
  //   list: [
  //     { id: '1', listItem: 'Semester & year-long course options' },
  //     { id: '2', listItem: 'Learn real quantum skills' },
  //     { id: '3', listItem: 'Run code on a quantum computer' },
  //     { id: '4', listItem: 'Industry expert Q&As' },
  //   ],
  //   img: quantumComputingLab,
  //   links: [
  //     {
  //       text: 'Learn More',
  //       link: 'https://www.qubitbyqubit.org/programs',
  //       isExternal: true,
  //     },
  //     {
  //       text: 'Interest Form',
  //       isExternal: true,
  //       link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  //     },
  //   ],
  // },


  {
    id: '1',
    title: 'Quantum Summer Institute',
    subTitle: <Fragment>
      <span className='d-block mb-5'>University students</span>
      <span className='d-block'>May 27 - June 20, 2025</span>
    </Fragment>,
    list: [
      { id: '1', listItem: 'Develop real quantum skills' },
      { id: '2', listItem: '4-week intensive quantum training' },
      { id: '3', listItem: 'Construct circuits and quantum gates' },
      { id: '4', listItem: 'Learn quantum algorithms' },
      { id: '5', listItem: 'Run code on a quantum computer' },
    ],
    img: quantumComputingLab,
    links: [
      {
        text: 'Learn More',
        link: 'https://www.qubitbyqubit.org/qsi',
        isExternal: true,
      },
      {
        text: 'Apply Now',
        isExternal: true,
        link: 'https://www.jotform.com/form/250306119741147',
      },
    ],
  },


  {
    id: '2',
    title: 'Early Quantum Career Immersion',
    list: [
      {
        id: '1',
        listItem: (
          <Fragment>
            Complete a research project or be <br />
            placed in an industry internship
          </Fragment>
        ),
      },
      {
        id: '2',
        listItem: (
          <Fragment>
            Develop critical skills for <br />a future career in quantum
          </Fragment>
        ),
      },
      {
        id: '3',
        listItem: (
          <Fragment>
            Learn about academic and <br />
            career pathways from quantum mentors
          </Fragment>
        ),
      },
    ],
    img: quantumImmersion,
    links: [
      {
        text: 'Learn More',
        link: 'https://www.qubitbyqubit.org/eqci',
        isExternal: true,
      },
      {
        text: 'Apply',
        link: 'https://qubitbyqubit.com/eqci-application',
        isExternal: true,
      },
    ],
  },
]

export const programPostEducators = [
  // {
  //   id: '1',
  //   title: (
  //     <Fragment>
  //       Artificial Intelligence
  //       <small className="d-block">High School Educators</small>
  //     </Fragment>
  //   ),
  //   list: [
  //     { id: '1', listItem: 'PD in AI/ML' },
  //     { id: '2', listItem: 'Bring ML into your classroom' },
  //     { id: '3', listItem: 'CEU opportunity' },
  //     { id: '4', listItem: 'STEM educators community' },
  //   ],
  //   img: ppeArtificialIntelligence,
  //   links: [
  //     { text: 'Learn More', link: '/train-2-weeks-pd', isExternal: false },
  //   ],
  // },

  // {
  //   id: '2',
  //   title: (
  //     <Fragment>
  //       Machine Learning PD <span className="text-thin text-italic">with</span>{' '}
  //       AWS
  //       <small small className="d-block">
  //         Community College, HBCU & MSI Faculty
  //       </small>
  //     </Fragment>
  //   ),
  //   list: [
  //     { id: '1', listItem: '2-week Bootcamps' },
  //     { id: '2', listItem: 'Stipend provided' },
  //     { id: '3', listItem: 'CEU opportunity' },
  //     { id: '4', listItem: 'Learn real AI/ML skills' },
  //     { id: '4', listItem: 'FREE - funded by AWS' },
  //   ],
  //   img: aiCloud,
  //   links: [
  //     { text: 'Learn More', link: '/train-aws-pd', isExternal: false },
  //     {
  //       text: 'Interest Form',
  //       isExternal: true,
  //       link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  //     },
  //   ],
  // },
  // {
  //   id: '3',
  //   title: (
  //     <Fragment>
  //       Quantum Computing
  //       {/* <small small className="d-block">
  //         High School Educators
  //       </small> */}
  //       <small small className="d-block">
  //         High School & Above
  //       </small>
  //     </Fragment>
  //   ),
  //   subTitle: 'Sept 2025 - April 2026',
  //   list: [
  //     { id: '1', listItem: 'Semester & year-long course options' },
  //     { id: '2', listItem: 'Develop skills in quantum computing' },
  //     { id: '3', listItem: 'Code in a real quantum computer' },
  //     { id: '4', listItem: 'Meet leading quantum experts' },
  //   ],
  //   img: ppeQuantumComputing,
  //   links: [
  //     {
  //       text: 'Learn More',
  //       link: 'https://www.qubitbyqubit.org/programs',
  //       isExternal: true,
  //     },
  //     {
  //       text: 'Interest Form',
  //       isExternal: true,
  //       link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  //     },
  //   ],
  // },

  {
    id: '1',
    title: 'Artificial Intelligence PD',
    subTitle: <Fragment>
      <span className='d-block mb-5'>US Public School Educators</span>
      <span className="d-block mb-5">FREE</span>
      <span className="d-block">July 21 - 25, 2025</span>
    </Fragment>,
    list: [
      { id: '1', listItem: 'Learn about the cutting edge field of artificial intelligence' },
      { id: '2', listItem: 'Explore how to integrate AI/ML into your existing classes' },
      { id: '3', listItem: 'Develop a network of STEM Educators' },
      { id: '4', listItem: 'CEU Opportunity' },
    ],
    img: techCamp,
    links: [
      {
        isExternal: false,
        text: 'Learn More & Register',
        link: '/train-2-weeks-pd',
      },
    ],
  }, {
    id: '',
    title: 'Emerging Tech Workshops',
    subTitle: (<Fragment>
      Middle & High School classrooms
      <span className="d-block">Jan - June 2025</span>
      <span className="d-block"><br /></span>
    </Fragment>),
    list: [
      { id: '1', listItem: 'Bring AI, Data Science & Quantum into your classrooms', },
      { id: '2', listItem: 'Inspire tech & non-tech students with cutting-edge skills', },
      { id: '3', listItem: 'Hands-on activities', },
      { id: '4', listItem: 'FREE instructor-led workshops sponsored by Keysight Technologies', },
    ],
    img: quantumComputingPd,
    links: [
      {
        isExternal: true,
        text: 'Request a workshop',
        link: 'https://airtable.com/appAgeNP0hq6rXtT0/pagifuoMwrfUdxzoB/form',
      },
    ],
  }, {
    id: '',
    title: (<Fragment>
      Intro to Machine Learning Bootcamp
      <small className="text-thin"> with</small> AWS
    </Fragment>),
    subTitle: (<Fragment>
      Community College, HBCU & MSI Faculty
      <span className="d-block">2025 bootcamps announced soon</span>
      <span className="d-block"><br /></span>
    </Fragment>),
    list: [
      { id: '1', listItem: 'Learn tangible artificial intelligence and machine learning skills', },
      { id: '2', listItem: 'Earn CEU credits from Johns Hopkins University', },
      { id: '3', listItem: 'Collaborate with a community of educators nationwide', },
    ],
    img: aiCloud,
    links: [
      {
        isExternal: false,
        text: 'Learn more',
        link: '/train-aws-pd',
      },
      {
        isExternal: true,
        text: '2025 Interest Form',
        link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
      },
    ],
  },
]
