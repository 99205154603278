import {
  banner,
  aiIntro,
  courseOverview,
  whoShouldJoin,
  coursesPartner,
  forSchools,
  faqs,
  leadInstructors,
} from 'data/trainSemesterCourse'

import { TrainCourseBanner } from 'component/TrainCourse/TrainCourseBanner'
import { AiIntro } from 'component/TrainCourse/AiIntro'
import { CoursesBy } from 'component/TrainCourse/CoursesBy'
import { CourseOverview } from 'component/TrainCourse/CourseOverview'
import { WhoShouldJoin } from 'component/TrainCourse/WhoShouldJoin'
import { LeadInstructors } from 'component/TrainCourse/LeadInstructors'
import { ForSchools } from 'component/TrainCourse/ForSchools'
import { Faq } from 'component/TrainCourse/Faq'

export const TrainSemesterCourse = () => {
  return (
    <div className="train-course-page">
      <TrainCourseBanner
        bannerTitle={banner.bannerTitle}
        rotatingWords={banner.rotatingWords}
        bannerSubTitle={banner.bannerSubTitle}
        additionalData={banner.additionalData}
        buttons={banner.buttons}
      />
      <AiIntro
        paragraph={aiIntro.paragraph}
        paraTwo={aiIntro.paraTwo}
        aiList={aiIntro.aiList}
        tagLine={aiIntro.tagLine}
      />
      <CoursesBy title={coursesPartner.title} logos={coursesPartner.logos} />
      <CourseOverview data={courseOverview} />
      <WhoShouldJoin
        words={whoShouldJoin.words}
        text={whoShouldJoin.text}
        isAnimated={false}
      />
      <LeadInstructors
        isMultiple={true}
        data={leadInstructors}
        headingClassName="mb-40"
      />
      <ForSchools
        title={forSchools.sectionTitle}
        desc={forSchools.sectionDesc}
        buttonLink={forSchools.buttonLink}
        logos={forSchools.logos}
        isSemesterCourse={true}
        button={forSchools.button}
      />
      {/* <Faq faqs={faqs} /> */}
    </div>
  )
}
