import { Fragment } from 'react'
import bg from 'assets/img/summer-programs/artificial-intelligence.jpg'

export const banner = {
  bannerBg: bg,
  heading: 'YEAR OF EMERGING TECH',
  programs:
    'Artificial Intelligence | Quantum Computing | Data Science | Cybersecurity',
  programsTypes: 'PDs, Webinars, Intensives & Research Opportunities',
  programsFor: 'Grades: 9 – 12, University + Educators',
  bannerLinks: [
    {
      linkText: (
        <Fragment>
          <span>
            <span>PD: Intro to AI Bootcamp</span>
            Learn More & Register
          </span>
        </Fragment>
      ),
      link: '/train-2-weeks-pd',
      isExternal: false,
    },
    {
      linkText: (
        <Fragment>
          <span>
            <span>Free Webinars</span>
            Register Now
          </span>
        </Fragment>
      ),
      link: "https://linktr.ee/the_coding_school",
      isExternal: true,
    },
    {
      linkText: (
        <Fragment>
          <span>
            <span>Research Opportunities</span>
            Apply Now
          </span>
        </Fragment>
      ),
      link: "/nhsrp-application",
      isExternal: false,
    },
  ],

}
